@import 'src/assets/styles/theme.style.scss';

.reset-form {
    max-width: 400px;
    margin: auto;
    margin-top: 10px;
    color: rgb(107, 107, 107);
    background-color: rgba(255, 255, 255, 0.555);
    padding: 30px;
    border-radius: 20px;
    font-size: 15px;
  }
.alert {
    margin: 1rem 0rem;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
