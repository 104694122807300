@import 'src/assets/styles/theme.style.scss';

.users-page {
  height: 100%;

  .users-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  .users-table > thead {
    background: #faf6ed;
    text-align: left;
    padding: 8px 20px;
    font-size: 14px;
    color: #adabac;
    font-weight: normal;
  }

  .header {
    padding: 30px 10px 10px 10px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }

  .users-navbar {
    border-bottom: 1px solid #e1dcd3;
    margin: 0;
    padding: 10px;
    background-color: #faf6ed;
  }

  .notification-component {
    position: absolute;
    top: 70px;
    right: 20px;
    width: 420px;
    height: 527px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #1c9a7e1a;
    border-radius: 10px;
    z-index: 3;

    .toast-header {
      padding: 21px 16px 16px 16px;
      border-bottom: 1px solid #eceff5;
      border-radius: 0px;
      background-color: rgba(255, 255, 255, 0);

      .toast-title {
        font-size: 14px;
        line-height: 26px;
        font-family: Montserrat;
        font-weight: bold;
        color: #12203e;
        opacity: 1;
        margin: 0px;
      }
    }

    .toast-body {
      height: 87%;
      max-width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px;
      margin: 0px;

      .active {
        .status {
          background-color: $primary-color;
        }

        .name {
          color: $primary-color;
        }
      }

      .status {
        margin-left: 16px;
        height: 8px;
        width: 8px;
        background: #d4d7e0 0% 0% no-repeat padding-box;
        border-radius: 50%;
        display: inline-block;
      }

      .strong,
      .name {
        font: normal normal 600 12px/16px Montserrat;
        color: #12203e;
      }

      .notifications-container:hover {
        background-color: #f2f5fa;
      }

      .notifications-container {
        padding: 10px 0px;
        margin: 0px 0px 15px 0px;

        .notification-data {
          margin-left: 32px;
          padding-bottom: 0px;
          padding-top: 0px;
          margin-top: -16px;
          cursor: pointer;

          small {
            border: #d4d7e0;
          }

          .notification-info {
            p {
              display: inline;
              color: #12203e;
              font-weight: 500;
            }
          }

          .message {
            color: #7786a4;
            max-width: 147px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .date-time {
            font: normal normal normal 10px/16px Montserrat;
            color: #7786a4;
            display: inline-block;
            margin: auto;
          }

          .date-time-icon {
            background-position: center;
            width: 16px;
            height: 14px;
            display: inline-block;
            margin-right: 8px;
            text-align: center;
            background: transparent 0% 0% no-repeat padding-box;
            display: inline-block;
            vertical-align: middle;
          }

          p,
          .icon {
            font: normal normal normal 12px/16px Montserrat;
            color: #12203e;
            margin-bottom: 0px;
            padding-bottom: 8px;
          }
        }
      }

      .toast .icon {
        color: $primary-color;
        float: left;
      }

      .toast .datetime {
        float: left;
      }
    }
  }
}

.badge {
  position: absolute;
  width: 11px;
  height: 12px;
  background: #fff 0% 0% no-repeat padding-box;
  // top: -10px;
  // right: -10px;
  border-radius: 50%;
  font-family: Montserrat;
  font-size: 8px;
  color: #000;
  align-items: center;
  justify-content: center;
}
.tab-footer {
  border-top: 1px solid #eaeff4;
  padding: 8px;
  margin-top: auto;
}

.action-buttons {
  width: fit-content;
  margin-left: auto;

  button {
    width: 98px;
    height: 40px;
    margin-right: 20px;
  }
}