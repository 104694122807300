.side-menu {
  height: 100%;
  padding-bottom: 20px;

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    height: 100%;
    width: 100%;

    .item {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 200px;
      height: 48px;
      padding-left: 20px;
      border-radius: 10px;
      cursor: pointer;
      color: var(--item-color);
      font-family: Montserrat, medium;

      a {
        width: 100%;
        text-decoration: none;
        color: inherit;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .item-icon {
        margin-right: 10px;
      }

      .healbotAvatar-icon{
        margin-right: 10px;
        font-size: 20px;
        width: 24px;
        align-items: center;
      }

      .healbot-text{
        width: 63px;
        align-items: center;
      }
    }

    .active {
      box-shadow: 0px 10px 20px var(--active-shadow-color);
      background-color: var(--active-color);
      color: white;
    }

    .bottom-items {
      margin-top: auto;
    }
  }
}
