@import 'src/assets/styles/theme.style.scss';

.reset-page {
  height: 100%;
  background: $login-background;
  background-size: $login-background-size;

  .container {
    max-width: 400px;
  }
  
  .main-logo {
    height: 240px;
    background-size: 105% auto;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.alert {
    margin: 1rem 0rem;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
